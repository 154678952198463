export const Customer = (props) => {
  return (
    <div id="customers">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/1-min.jpg" className="img-responsive" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>Ce Spun Clienții</h2>
              <p>{props.data ? props.data.paragraph : "loading..."}</p>
              <h3>{props.data ? props.data.customer_name : "loading..."}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
